img[src*="ten"]{ width: 10%}
img[src*="ten-right"] { width: 10%; float: right; }
img[src*="ten-left"] { width: 10%; float: left }

img[src*="twenty"]{ width: 20%}
img[src*="twenty-right"] { width: 20%; float: right; }
img[src*="twenty-left"] { width: 20%; float: left }

img[src*="thirty"]{ width: 30%}
img[src*="thirty-right"] { width: 30%; float: right; }
img[src*="thirty-left"] { width: 30%; float: left }

img[src*="forty"]{ width: 40%}
img[src*="forty-right"] { width: 40%; float: right; }
img[src*="forty-left"] { width: 40%; float: left }

img[src*="fifty"]{ width: 50%}
img[src*="fifty-right"] { width: 50%; float: right; }
img[src*="fifty-left"] { width: 50%; float: left }

img[src*="sixty"]{ width: 60%}
img[src*="sixty-right"] { width: 60%; float: right; }
img[src*="sixty-left"] { width: 60%; float: left }

img[src*="seventy"]{ width: 70%}
img[src*="seventy-right"] { width: 70%; float: right; }
img[src*="seventy-left"] { width: 70%; float: left }

img[src*="eighty"]{ width: 80%}
img[src*="eighty-right"] { width: 80%; float: right; }
img[src*="eighty-left"] { width: 80%; float: left }

img[src*="ninety"]{ width: 90%}
img[src*="ninety-right"] { width: 90%; float: right; }
img[src*="ninety-left"] { width: 90%; float: left }

img[src*="one-hundred"]{ width: 100%}